<template>
  <div>
    <v-container>
      <h1>404 not found</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "not-found",
};
</script>

<style lang="scss" scoped></style>
